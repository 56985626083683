import React, { useContext, useEffect, useState } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const App = () => {
 
  const Darkmode=useSelector((state)=>state.services.theme)
  //  const theme=localStorage.getItem('darkmode')
  const [theme,setTheme]=useState(localStorage.getItem('darkmode')=="true")

  useEffect(()=>{
    
  setTheme(Darkmode)
  },[Darkmode])
  // useEffect(()=>{
  //   console.log(Boolean(localStorage.getItem('darkmode')))
  // setTheme(localStorage.getItem('darkmode')=="true")
  // },[])
   
  return (
    <div className={!theme? 'dark-mode':'App'} id="scrool">
      <AllRoute />
      <ToastContainer />
    </div>
  );
};

export default App;
