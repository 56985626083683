import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const getAllServices=createAsyncThunk('getAllServices',
async (data, rejectWithValue ) => {
    try {

      const response = await axios.get(`${BASEURL}/website/service/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

  export const getSpecialService=createAsyncThunk('getSpecialService',
async (id, rejectWithValue ) => {
    try {

      const response = await axios.get(`${BASEURL}/website/service/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })
  const ServicesSlice=createSlice({
    name:'services',
    initialState:{
        loading:false,
        error:null,
        services:[],
        service:{},
        theme:localStorage.getItem('darkmode')=="true",
    },
    reducers:{
      setTheme:(state,action)=>{
        console.log(action.payload);
        state.theme=action.payload
        localStorage.setItem('darkmode',action.payload)
      }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllServices.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.services=[]
        })
        .addCase(getAllServices.fulfilled,(state,action)=>{
            state.loading=false
            state.services=action.payload
            
        })
        .addCase(getAllServices.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
        builder
        .addCase(getSpecialService.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.service={}
        })
        .addCase(getSpecialService.fulfilled,(state,action)=>{
            state.loading=false
            state.service=action.payload
           
        })
        .addCase(getSpecialService.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
    }

  })
  export const { setTheme } = ServicesSlice.actions;
  export  default ServicesSlice.reducer