import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const getAllEmployees=createAsyncThunk('getAllEmployees',
async (data, rejectWithValue ) => {
    try {

      const response = await axios.get(`${BASEURL}/website/employee/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

  export const getSpecialEmployee=createAsyncThunk('getSpecialEmployee',
async (id, rejectWithValue ) => {
    try {

      const response = await axios.get(`${BASEURL}/website/employee/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

  const EmployeesSlice=createSlice({
    name:'employees',
    initialState:{
        loading:false,
        error:null,
        employees:[]
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(getAllEmployees.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.employees=[]
        })
        .addCase(getAllEmployees.fulfilled,(state,action)=>{
            state.loading=false
            state.employees=action.payload
           
        })
        .addCase(getAllEmployees.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
        builder
        .addCase(getSpecialEmployee.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.employees=[]
        })
        .addCase(getSpecialEmployee.fulfilled,(state,action)=>{
            state.loading=false
            state.employees=action.payload
           
        })
        .addCase(getSpecialEmployee.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
    }

  })
  export  default EmployeesSlice.reducer