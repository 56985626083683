import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useDispatch } from "react-redux";
import { AddtoCart } from "../../store/pages/cart";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {toast} from "react-toastify";
const Product = ({ item, addToCart },props) => {
  const [sub, setSub] = useState("");
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const token=localStorage.getItem("token")
  const addToCartProduct = (product, qty = 1) => {
    addToCart(product, qty);
    dispatch(AddtoCart({ service_id: item.id, qty, subservice_id: sub }));
  };
  return (
        <section className={`wpo-shop-section section-padding  wpo-team-section `}>

        <div className="container">
      <div className="row align-items-start ">
      <div className="col col-lg-5 col-12">
        <div className="shop-single-slider">
          <div className="slider-nav">
            <div>
              <Zoom>
                <img src={item.img ? item.img : ""} alt="products" />
              </Zoom>
            </div>
          </div>
        </div>
      </div>
      <div className="col col-lg-7 col-12">
        <div className="product-details wpo-section-title">
          <h2>{item.name}</h2>
          {/* <div className="product-rt">
                  <div className="rating">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star-o"></i>
                  </div>
                  <span>(25 customer reviews)</span>
                </div> */}
          <div className="price">
            <span className="current">
              ${item.from_price}- {item.to_price}
            </span>
            <span className="old">${item.delPrice}</span>
          </div>
          <p dangerouslySetInnerHTML={{ __html: item.description }}></p>


          <div className="product-option">
            <div className="product-row">
                <FormControl dir="rtl" sx={{ m: 1,mb : 3, minWidth: 120 ,textAlign : "right"}} fullWidth>
                    <InputLabel id="demo-simple-select-label" sx={{ fontSize : "1.5rem" , color : "gray"}}>اختر خدمتك</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={sub}
                        onChange={(e) => setSub(e.target.value)}
                        label="اختر خدمتك"
                    >
                        {item?.subservices?.map((subService) => (
                            <MenuItem   key={subService.id} value={subService.id}>
                                {subService.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
              <button
                className="theme-btn "
                data-bs-toggle="tooltip"
                data-bs-html="true"
                title="Add to Cart"
                onClick={() => {
                  if(token){
                    if (sub) {
                    addToCartProduct(item)
                  } else {
                        toast.info('يلزم اختيار خدمتك !');
                  }
                  }else {
                    navigate("/signin")
                  }

                }
                  
                  }
              >
                Add to cart
              </button>
              <div></div>
            </div>
          </div>

          {/* <div className="tg-btm">
                  <p><span>Categories:</span> Book</p>
                  <p><span>Tags:</span> law, events, consult</p>
                </div> */}
        </div>
      </div>
    </div>
          </div>
        </section>
  );
};

export default Product;
