import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllServicesElectronic, getSpecialElectronic } from "../../store/pages/electronicSlice";
import {getProfile} from "../../store/pages/profileSlice";


const ShopProduct = ({ products, addToCartProduct } ,props) => {
    const { electronics, loading } = useSelector((state) => state.electronics);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const hundlesearch = (e) => {
        dispatch(getSpecialElectronic(e.target.value));
    }
    const ClickHandler = (id) => {
      window.scrollTo(10, 0);
      dispatch(getSpecialElectronic(id));
    };
    useEffect( () => {
        if (!electronics.results) {
            dispatch(getAllServicesElectronic());
        }
    }, [dispatch,electronics.results]);
    console.log(electronics);

  return (
    <section className={`wpo-shop-section section-padding " wpo-team-section section-padding ${props.tmClass}`}>
        <div className="container">
            <div className="d-flex justify-content-start mx-auto mb-3 ">
                <input onChange={hundlesearch} className="form-control w-50 p-3" type="text" placeholder="ابحث عن خدماتك"/>
            </div>
            <div className="row">
                <div className="col col-xs-12">
                    <div className="shop-grids clearfix">
                        {!loading && electronics?.results && electronics.results?.length > 0 &&
                            electronics?.results?.map((product, pitem) => (
                                <div className="grid" key={pitem}>
                                    <div className="img-holder">
                                        <img src={product.img} alt="logo" loading="lazy"/>
                                    </div>
                                    <div className="details ">
                                        <h3><Link onClick={ClickHandler}
                                                  to={`/product-single/${product.id}`}>{product.name}</Link></h3>
                                        <span>${product.from_price}</span>
                                        {/*<div className="add-to-cart">*/}
                                        {/*    <button*/}
                                        {/*        data-bs-toggle="tooltip"*/}
                                        {/*        data-bs-html="true"*/}
                                        {/*        title="Add to Cart"*/}
                                        {/*        onClick={() => {*/}
                                        {/*            addToCartProduct(product)*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        Add to cart*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            ))}
                    </div>
                    {/*<div className="pagination-wrapper pagination-wrapper-center">*/}
                    {/*    <ul className="pg-pagination">*/}
                    {/*        <li>*/}
                    {/*            <Link to="/" aria-label="Previous">*/}
                    {/*                <i className="ti-angle-left"></i>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*        <li className="active"><Link to="/">1</Link></li>*/}
                    {/*        <li><Link to="/">2</Link></li>*/}
                    {/*        <li><Link to="/">3</Link></li>*/}
                    {/*        <li>*/}
                    {/*            <Link to="/" aria-label="Next">*/}
                    {/*                <i className="ti-angle-right"></i>*/}
                    {/*            </Link>*/}
                    {/*        </li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    </section>
  );
};

export default ShopProduct;
