import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const Signup=createAsyncThunk('Signup',
async (data, rejectWithValue ) => {
    try {

      const response = await axios.post(`${BASEURL}/auth/users/`,{
        ...data
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

  
  const registerSlice=createSlice({
    name:'register',
    initialState:{
        loading:false,
        error:null,
        register:{},
    },
    reducers:{ },
    extraReducers:(builder)=>{
        builder
        .addCase(Signup.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.register={}
        })
        .addCase(Signup.fulfilled,(state,action)=>{
            state.loading=false
            state.register=action.payload
            
        })
        .addCase(Signup.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
      
    }

  })

  export  default registerSlice.reducer