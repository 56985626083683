import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const Signin=createAsyncThunk('getAllServices',
async (data, rejectWithValue ) => {
    try {

      const response = await axios.post(`${BASEURL}/login/jwt/create/`,{
        ...data
      });
      localStorage.setItem('token',response.data.access)
      console.log(response.data.access);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

  
  const signinSlice=createSlice({
    name:'signin',
    initialState:{
        loading:false,
        error:null,
        user:{},
    },
    reducers:{ },
    extraReducers:(builder)=>{
        builder
        .addCase(Signin.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.user={}
        })
        .addCase(Signin.fulfilled,(state,action)=>{
            state.loading=false
            state.user=action.payload
            
           
        })
        .addCase(Signin.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
      
    }

  })

  export  default signinSlice.reducer