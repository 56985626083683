import React from "react";
import abimg2 from "../../images/about/MHD2-1024x1020.jpg";
import abimg from "../../images/about/mm.jpg";


const About = (props) => {
  return (
    <section
      className={`wpo-about-section-s3 section-padding ${props.abClass}`}
    >
      <div className="container">
        <div className="wpo-section-title-s2">
          <span>من نحن</span>
          <h2 style={{textAlign:'center'}}>شركة احكام للمحاماة والاستشارات القانونية</h2>
        </div>
        <div className="wpo-about-section-wrapper">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="wpo-about-content">
                <h2>شركة محاماة متخصصة </h2>
                <div className="wpo-about-content-inner">
                  <p>
                    مرخصة من وزارة العدل منذ عام 1438هـ، ومسجلة في عضوية المنشأة
                    القانونية لدى الهيئة السعودية للمحامين. نعمل كإستشاريين
                    ومشرفين قانونيين للعملاء، فنقدم لهم الرأي والنصح القانوني
                    المطلوب في جميع مراحل أعمالهم، سواء قبل التعاقد مع الغير أو
                    بعده، أو أثناء حصول الخلافات مع الغير من الجهات الحكومية أو
                    الخاصة، بما يحقق لعملائنا أفضل المصالح وفق الإطار النظامي
                    المتاح.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="wpo-about-img">
                <img src={abimg} alt="" />
                {/* <div className="about-img-inner">
                  <img src={abimg2} alt="" />
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="visible-text">
            <h2>About</h2>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default About;
