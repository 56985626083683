import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import {toast} from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Link, useNavigate} from "react-router-dom";

import './style.scss';
import {useDispatch} from 'react-redux';
import {Signin} from '../../store/pages/signinSlice';


const LoginPage = (props) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const push = useNavigate()
    const dispatch = useDispatch()
    const [value, setValue] = useState({
        email: '', password: '', // remember: false,
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const rememberHandler = () => {
        // setValue({...value, remember: !value.remember});
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));


    const submitForm = (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setValue({
                email: '', password: '',

            });
            validator.hideMessages();

            const userRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            const userPasswrod = /^(?=.*\d).{6,}$/;
            const password = value.password;
            const email = value.email;

            if (email.match(userRegex) && password.match(userPasswrod)) {
                dispatch(Signin(value)).then((res) => {
                    console.log(res.meta.requestStatus === "fulfilled")
                    if (res.meta.requestStatus === "fulfilled") {
                    toast.success('Successfully Login on Ehkam !');
                    push('/home');
                    }else {
                        toast.warning('email or password is incorrect !');
                    }
                }).catch(e => {
                    toast.error(e.message);
                })

            }
        } else {
            validator.showMessages();
            toast.error('Empty field is not allowed!');

        }
    };
    useEffect(() => {

        if (token) {
            navigate('/');
        } else {
            // If the user is not authenticated, redirect to the login page
            navigate('/signin');
        }
    }, [navigate]);
    console.log(value);
    return (<Grid className="loginWrapper">
            <Grid className="loginForm">
                <h2>Sign In</h2>
                <p>Sign in to your account</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('email', value.email, 'required|email')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                name="password"
                                type="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formAction">
                                <FormControlLabel
                                    control={<Checkbox checked={value.remember} onChange={rememberHandler}/>}
                                    label="Remember Me"
                                />
                                <Link to="/forgot-password">Forgot Password?</Link>
                            </Grid>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtnTheme" type="submit">Login</Button>
                            </Grid>
                            {/*<Grid className="loginWithSocial">*/}
                            {/*    <Button className="facebook"><i className="fa fa-facebook"></i></Button>*/}
                            {/*    <Button className="twitter"><i className="fa fa-twitter"></i></Button>*/}
                            {/*    <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>*/}
                            {/*</Grid>*/}
                            <p className="noteHelp fs-2">Don't have an account? <Link to="/register">Create free
                                account</Link>
                            </p>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>)
};

export default LoginPage;