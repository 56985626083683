import React, {Fragment, useEffect, useState} from "react";
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import {totalPrice} from "../../utils";
import {
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} from "../../store/actions/action";
import Footer from "../../components/footer/Footer";
import {getCart} from "../../store/pages/cart";

const CartPage = (props) => {
  const {cart, loading} = useSelector((state) => state.cart)
  console.log("hamadaaaaaaaaaaaaaaaa",cart)
  const dispatch = useDispatch();
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  useEffect(() => {
    dispatch(getCart())

  }, [dispatch]);
  return (
    <Fragment>
      <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
      <PageTitle pageTitle={"Cart"} pagesub={"Cart"} />
      <div className="cart-area section-padding wpo-practice-section-s2">
        <div className="container">
          <div className="form">
            <div className="cart-wrapper">
              <div className="row">
                <div className="col-12">
                  <form action="cart">
                    <table className="table-responsive cart-wrap">
                      <thead>
                        <tr>
                          <th className="images images-b">Image</th>
                          <th className="product-2">Product Name</th>
                          <th className="pr">Quantity</th>
                          <th className="ptice">Price</th>
                          <th className="stock">Total Price</th>
                          <th className="remove remove-b">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart &&
                          cart?.length > 0 &&
                          cart?.map((catItem, crt) => (
                            <tr key={crt}>
                              <td className="images">
                                <img src={catItem.service.img} alt="" />
                              </td>
                              <td className="product">
                                <ul>
                                  <li className="first-cart">
                                    {catItem.service.name}
                                  </li>
                                </ul>
                              </td>
                              <td className="stock" >
                                <div className="pro-single-btn" >
                                  <Grid className="quantity cart-plus-minus" >
                                    {/*<Button*/}
                                    {/*  className="dec qtybutton"*/}
                                    {/*  onClick={() =>*/}
                                    {/*    props.decrementQuantity(catItem.id)*/}
                                    {/*  }*/}
                                    {/*>*/}
                                    {/*  -*/}
                                    {/*</Button>*/}
                                    <input value={catItem.quantity} type="text" style={{background:"#af914e",border:"none"}} />
                                    {/*<Button*/}
                                    {/*  className="inc qtybutton"*/}
                                    {/*  onClick={() =>*/}
                                    {/*    props.incrementQuantity(catItem.id)*/}
                                    {/*  }*/}
                                    {/*>*/}
                                    {/*  +*/}
                                    {/*</Button>*/}
                                  </Grid>
                                </div>
                              </td>
                              <td className="ptice">${catItem.total_price}</td>
                              <td className="stock">${catItem.quantity * catItem.total_price}</td>
                              <td className="action">
                                <ul>
                                  <li
                                    className="w-btn"
                                    onClick={() =>
                                      props.removeFromCart(catItem.id)
                                    }>
                                    <i className="fi ti-trash"></i>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </form>
                  <div className="submit-btn-area">
                    <ul>
                      <li>
                        <Link
                          onClick={ClickHandler}
                          className="theme-btn"
                          to="/shop"
                        >
                          Continue Shopping{" "}
                        </Link>
                      </li>
                      {/*<li>*/}
                      {/*  <button type="submit">Update Cart</button>*/}
                      {/*</li>*/}
                    </ul>
                  </div>
                  <div className="cart-product-list">
                    <ul>
                      <li className="cart-b">
                        Total product<span>( {cart?.length} )</span>
                      </li>
                      <li className="cart-b">
                        Sub Price<span>${totalPrice(cart)}</span>
                      </li>
                      {/*<li>*/}
                      {/*  Vat<span>$0</span>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  Eco Tax<span>$0</span>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  Delivery Charge<span>$0</span>*/}
                      {/*</li>*/}
                      <li className="cart-b">
                        Total Price<span>${totalPrice(cart)}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="submit-btn-area">
                    <ul>
                      <li>
                        <button
                          onClick={ClickHandler}
                          className="submit-btn-area"
                        >
                          Proceed to Checkout{" "}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state?.cartList?.cart,
  };
};
export default connect(mapStateToProps, {
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
})(CartPage);
