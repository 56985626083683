import React, {createContext, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
import {totalPrice} from "../../utils";
import {connect, useDispatch, useSelector} from "react-redux";
import {removeFromCart} from "../../store/actions/action";
import Logo from "../../images/logo (1).png";
import HeaderTopbar from "../HeaderTopbar";
import {setTheme} from "../../store/pages/servicesSlice";
import {DarkModeSwitch} from "react-toggle-dark-mode";
import {DeletefromCart, getCart} from "../../store/pages/cart";

export const DarkMode = createContext();

const Header = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [cartActive, setcartState] = useState(false);
    const Darkmode = useSelector((state) => state.services.theme)
    const {cart, loading,IsDeleted} = useSelector((state) => state.cart)
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const [showDiv, setShowDiv] = useState(false);
    const location = useLocation()
    const path = location.pathname

    const SubmitHandler = (e) => {
        e.preventDefault();
    };
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };
    // const { carts } = props;
    const theme = localStorage.getItem("darkmode");
    const dispatch = useDispatch();
    const toggleMode = (e) => {
        console.log(e);
        // setIsDarkMode(e.target.checked);
        dispatch(setTheme(e));
    };
    useEffect(() => {
        dispatch(getCart())
        if (path === "/shop" ) {
            setShowDiv(true)
        } else {
            setShowDiv(false)
        }
    }, [path]);

    useEffect(() => {
        if(!IsDeleted) {
            dispatch(getCart())
        }

    }, [IsDeleted]);


    return (<header id="header" className={props.topbarClass}>
            <HeaderTopbar/>
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-between">
                            <div className=" col-md-4 col-4 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu/>
                                </div>
                            </div>
                            <div className=" col-md-1 col-4">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/">
                                        <img src={Logo} alt=""/>
                                    </Link>
                                </div>
                            </div>
                            <div className=" col-md-10 col-1 d-none d-lg-block">
                                <div
                                    id="navbar"
                                    className="collapse navbar-collapse navigation-holder"
                                >
                                    <button className="menu-close">
                                        <i className="ti-close"></i>
                                    </button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/">
                                                الرئيسية
                                            </Link>
                                            {/* <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/home">Home style 1</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home2">Home style 2</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home3">Home style 3</Link></li>
                                                <li><Link onClick={ClickHandler} to="/home4">Home style 4</Link></li>
                                            </ul> */}
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/about">
                                                من نحن
                                            </Link>
                                            {/* <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                                <li><Link onClick={ClickHandler} to="/attorneys">Attorneys</Link></li>
                                                <li><Link onClick={ClickHandler} to="/attorneys-single/Jacob-Jones">Attorneys single</Link></li>
                                                <li><Link onClick={ClickHandler} to="/terms">Terms & Coditions</Link></li>
                                                <li><Link onClick={ClickHandler} to="/pricing">Pricing</Link></li>
                                                <li><Link onClick={ClickHandler} to="/404">404 Error</Link></li>
                                                <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                                <li><Link onClick={ClickHandler} to="/login">Login</Link></li>
                                                <li><Link onClick={ClickHandler} to="/register">Register</Link></li>
                                            </ul> */}
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/practice">
                                                خدمات الشركة
                                            </Link>
                                            {/* <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/practice">Practice</Link></li>
                                                <li><Link onClick={ClickHandler} to="/practice-s2">Practice Style 2</Link></li>
                                                <li><Link onClick={ClickHandler} to="/practice-s3">Practice Style 3</Link></li>
                                                <li><Link onClick={ClickHandler} to="/practice-single/Criminal-Law">Practice Single</Link></li>
                                            </ul> */}
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/shop">
                                                الخدمات الالكترونية
                                            </Link>
                                            {/* <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/case">Cases</Link></li>
                                                <li><Link onClick={ClickHandler} to="/case-s2">Cases Style 2</Link></li>
                                                <li><Link onClick={ClickHandler} to="/case-s3">Cases Style 3</Link></li>
                                                <li><Link onClick={ClickHandler} to="/case-single/Criminal-Law">Cases Single</Link></li>
                                            </ul> */}
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/profile">
                                                الملف التعريفى
                                            </Link>
                                            {/* <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/shop">Shop</Link></li>
                                                <li><Link onClick={ClickHandler} to="/product-single/Principles-and-Policies">Shop Single</Link></li>
                                                <li><Link onClick={ClickHandler} to="/cart">Cart</Link></li>
                                                <li><Link onClick={ClickHandler} to="/checkout">Checkout</Link></li>
                                            </ul> */}
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={ClickHandler} to="/recommendwebsite">
                                                مواقع تهمك
                                            </Link>
                                            {/* <ul className="sub-menu">
                                                <li><Link onClick={ClickHandler} to="/blog">Blog right sidebar</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog-left-sidebar">Blog left sidebar</Link></li>
                                                <li><Link onClick={ClickHandler} to="/blog-fullwidth">Blog fullwidth</Link></li>
                                                <li className="menu-item-has-children">
                                                    <Link onClick={ClickHandler} to="/">Blog details</Link>
                                                    <ul className="sub-menu">
                                                        <li><Link onClick={ClickHandler} to="/blog-single/Justice-May-For-You-If-You-Are-Innocent">Blog details right sidebar</Link>
                                                        </li>
                                                        <li><Link onClick={ClickHandler} to="/blog-single-left-sidebar/Justice-May-For-You-If-You-Are-Innocent">Blog details left
                                                            sidebar</Link></li>
                                                        <li><Link onClick={ClickHandler} to="/blog-single-fullwidth/Justice-May-For-You-If-You-Are-Innocent">Blog details
                                                            fullwidth</Link></li>
                                                    </ul>
                                                </li>
                                            </ul> */}
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/attorneys">
                                                {" "}
                                                فريق العمل
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/contact">
                                                اتصل بنا
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="col-md-1 col-4 gap-md-4 d-flex justify-content-end align-items-center ">
                                <div style={{width: "fit-content"}}>
                                    <DarkModeSwitch
                                        style={{color: "#af914e"}}
                                        checked={Darkmode}
                                        onChange={(e) => toggleMode(e)}
                                        size={30}
                                    />
                                    {/* <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      onChange={(e) => toggleMode(e)}
                      checked={Darkmode}
                      // defaultChecked={localStorage.getItem("darkmode")=="true"}
                    />
                    <label
                      class="form-check-label"
                      for="flexSwitchCheckDefault"
                    >
                      {Darkmode ? "Light Mode" : "Dark Mode"}
                    </label>
                  </div> */}
                                </div>
                                <div className="header-right pe-3 pe-md-1">
                                    <div className="mini-cart">
                                        {showDiv ? 
                                        
                                        <button className="cart-toggle-btn"
                                                           onClick={() =>  setcartState(!cartActive)}>
                                            {" "}
                                            <i className="fi flaticon-023-shopping-cart"></i>{" "}
                                            <span className="cart-count">{cart?.length}</span>
                                        </button> : null}
                                        <div
                                            className={`mini-cart-content ${cartActive ? "mini-cart-content-toggle" : ""}`}>
                                            <button className="mini-cart-close"
                                                    onClick={() => setcartState(!cartActive)}><i
                                                className="ti-close"></i></button>
                                            {loading ? <div className="loader"></div> : <div className="mini-cart-items">
                                                { cart && cart?.length > 0 && cart?.map((catItem, crt) => (
                                                    <div className="mini-cart-item clearfix" key={crt}>
                                                        <div className="mini-cart-item-image">
                                                                <span>
                                                                    <img src={catItem?.service?.img} alt="icon"/>
                                                                </span>
                                                        </div>
                                                        <div className="mini-cart-item-des">
                                                            <p>{catItem?.service?.name} </p>
                                                            <span className="mini-cart-item-price">
                                                                    ${catItem.total_price} 
                                                                </span>
                                                            <span className="mini-cart-item-quantity">
                                                                   Quantity: {catItem.quantity}
                                                                </span>
                                                        </div>
                                                        <button
                                                            onClick={() => {
                                                                props.removeFromCart(catItem.id)
                                                                dispatch(DeletefromCart(catItem.id))
                                                            }}
                                                            className="btn btn-sm btn-danger"
                                                        >
                                                            <i className="ti-close"></i>
                                                        </button>
                                                    </div>))}
                                            </div>}
                                            <div className="mini-cart-action clearfix">
                                                <span
                                                    className="mini-checkout-price">Subtotal: <span> ${totalPrice(cart)}</span></span>
                                                <div className="mini-btn ">
                                                    <Link to="/checkout" className={`view-cart-btn s1 bg-dark ${cart?.length > 0 ? "d-block" : "d-none"}`} style={{color: "#af914e"}}>Checkout</Link>
                                                    <Link to="/cart" className="view-cart-btn">View Cart</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="header-search-form-wrapper">
                                        <div className="cart-search-contact">
                                            <button onClick={() => setMenuState(!menuActive)} className="search-toggle-btn"><i
                                                className={`fi ti-search ${menuActive ? "ti-close" : "fi "}`}></i></button>
                                            <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
                                                <form onSubmit={SubmitHandler}>
                                                    <div>
                                                        <input type="text" className="form-control"
                                                            placeholder="Search here..." />
                                                        <button type="submit"><i
                                                            className="fi flaticon-026-search"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>);
};

const mapStateToProps = (state) => {
    return {
        carts: state.cart,
    };
};

export default connect(mapStateToProps, {removeFromCart})(Header);
