import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const wishListSlice = createSlice({
  name: 'wishList',
  initialState: {
    w_list: [],
  },
  reducers: {
    addToWishlist: (state, action) => {
      const productId = action.payload.id;
      const existingIndex = state.w_list.findIndex((product) => product.id === productId);

      if (existingIndex !== -1) {
        toast.error('Item Removed from Wishlist');
        state.w_list.splice(existingIndex, 1);
      } else {
        toast.success('Item Added to Wishlist');
        state.w_list.push({ ...action.payload });
      }
    },
    removeFromWishlist: (state, action) => {
      const w_productId = action.payload;
      const w_list = state.w_list.filter((product) => product.id !== w_productId);
      state.w_list = w_list;
    },
  },
});

export const { addToWishlist, removeFromWishlist } = wishListSlice.actions;

export default wishListSlice.reducer;
