import React, {Fragment, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom'
import { connect, useDispatch, useSelector } from "react-redux";
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { addToCart } from "../../store/actions/action";
import Product from './product'
import api from "../../api";
import ProductTabs from './alltab';
import Footer from '../../components/footer/Footer';
import { getSpecialElectronic } from '../../store/pages/electronicSlice';



const ProductSinglePage =(props) => {
    const { slug } = useParams()
    const dispatch=useDispatch()
    const {electronic}=useSelector((state)=>state.electronics)
    const productsArray = api();
    const Allproduct = productsArray

    
    const {addToCart} = props;
    const [product, setProduct] = useState({});
    
    useEffect(() => {
        
            
 dispatch(getSpecialElectronic(slug));
        
      
    }, []);
    
    const item = product[0];
console.log(electronic);
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle={electronic.name} pagesub={electronic.name}/>
                    {electronic ? <Product
                        item={electronic}
                        addToCart={addToCart}
                    /> : null}
                    {/* <ProductTabs/> */}

            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        products: state.data.products,
    }
};

export default connect(mapStateToProps, { addToCart })(ProductSinglePage);
