import React from 'react';
import ContactForm from '../ContactFrom'


const Contactpage = () => {

    return (<section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-12 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-011-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>العنوان</h2>
                                            <p>المدينة المنورة-الدائرى الثانى-برج الفارسي الدور الثانى,المملكة العربية
                                                السعوديه</p>
                                            <p>الرياض - حي الياسمين – طريق انس بن مالك,المملكة العربية السعوديه</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-037-email-1"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>البريد الالكترونى</h2>
                                            <p>info@ehkaam.com.sa</p>
                                            <p>البريد الإلكتروني، وعادة ما نرد في غضون 24 ساعة.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-009-telephone"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>اتصل بنا</h2>
                                            <p>9660148453388+</p>
                                            <p>الاتصال من 08:00 حتي 18:00 السبت:الخميس</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" wpo-section-title">
                            <h2>اتصل بنا</h2>
                            <p>راسلنا الأن وسنقوم بالرد عليك فى أسرع وقت</p>
                        </div>
                        <div className="wpo-practice-section-s2">
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </div>
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3621.3696576543575!2d46.635473876073796!3d24.817029046992765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2ee5c46092b08f%3A0xf0a689a95fbd105f!2z2KfZhNix2YrYp9i2INit2Yog2KfZhNmK2KfYs9mF2YrZhg!5e0!3m2!1sen!2sae!4v1703323774669!5m2!1sen!2sae"></iframe>

                </div>
                <div className="wpo-contact-map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d14485.368387914361!2d46.639417!3d24.817972!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1704797366919!5m2!1sen!2seg"></iframe>
                </div>
            </section>
        </section>)

}

export default Contactpage;
