import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const getProfile=createAsyncThunk('getAllProfiles',
async (data, rejectWithValue ) => {
    try {
      const response = await axios.get(`${BASEURL}/website/profile/1/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

  
  const profileSlice=createSlice({
    name:'profile',
    initialState:{
        loading:false,
        error:null,
        profile:{},
      
    },
    reducers:{ },
    extraReducers:(builder)=>{
        builder
        .addCase(getProfile.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.profile=[]
        })
        .addCase(getProfile.fulfilled,(state,action)=>{
            state.loading=false
            state.profile=action.payload
            
        })
        .addCase(getProfile.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
      
    }

  })

  export  default profileSlice.reducer