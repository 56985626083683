import React, { Fragment } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import OurTeamComponent from '../../components/OurTeam/ourTeam'
import PageTitle from '../../components/pagetitle/PageTitle'

const OurTeam = () => {
  return (
    <Fragment>
         <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
            <PageTitle pageTitle={'فريق العمل'} pagesub={'فريق العمل'}/> 
            <OurTeamComponent vClass={'d-none'}/>
    </Fragment>
  )
}

export default OurTeam