import { createStore, compose, applyMiddleware } from "redux";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// middlewares
import thunk from "redux-thunk";

// Import custom components

import { configureStore } from "@reduxjs/toolkit";
import servicesSlice from "./pages/servicesSlice";
import productSlice from "./pages/products";
import cartSlice from "./pages/cart";
import wishListSlice from "./pages/wishList";
import compareListSlice from "./pages/compare";
import employeesSlice from "./pages/employeesSlice";
import profileSlice from "./pages/profileSlice";
import articalsSlice from "./pages/articalsSlice";
import electronicSlice from "./pages/electronicSlice";
import registerSlice from "./pages/signupSlice"
import signinSlice from "./pages/signinSlice";
// const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
  reducer: {
    services: servicesSlice,
    employees: employeesSlice,
    data: productSlice,
    cart: cartSlice,
    wishList: wishListSlice,
    compareList: compareListSlice,
    profile: profileSlice,
    articals: articalsSlice,
    electronics: electronicSlice,
    register:registerSlice,
    signin:signinSlice
  },
});

export default store;
