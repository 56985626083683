import React, { Fragment } from 'react'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'
import { Websites } from '../../components/websites/Websites'

export const RecommendWebsite = () => {
  return (
    <Fragment>
    <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
    <PageTitle pageTitle={'مواقع تهمك'} pagesub={' مواقع تهمك '}/> 
    <Websites tmClass={'dark-bg s2'}/>
    <Footer/>
</Fragment>
  )
}
