import React, { Fragment } from 'react'
import FileProfile from '../../components/fileProfile/FileProfile'
import Navbar from '../../components/Navbar/Navbar'
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer'

const Profile = () => {
  return (
    <Fragment>
    <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'}/>
    <PageTitle pageTitle={'الملف التعريفى'} pagesub={'الملف التعريفى '}/>
    <FileProfile abClass={'wpo-about-section-s4'}/>
    <Footer/>
    </Fragment>
  )
}
export default Profile