import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const getAllArticals=createAsyncThunk('getAllArticals',
async (data, rejectWithValue ) => {
    try {

      const response = await axios.get(`${BASEURL}/hr/article/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

  export const getSpecialService=createAsyncThunk('getSpecialService',
async (id, rejectWithValue ) => {
    try {

      const response = await axios.get(`${BASEURL}/website/service/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })
  const ArticalsSlice=createSlice({
    name:'articals',
    initialState:{
        loading:false,
        error:null,
        articals:[],
        artical:{},
        
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(getAllArticals.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.articals=[]
        })
        .addCase(getAllArticals.fulfilled,(state,action)=>{
            state.loading=false
            state.articals=action.payload['results']
            
        })
        .addCase(getAllArticals.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
        builder
        .addCase(getSpecialService.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.artical={}
        })
        .addCase(getSpecialService.fulfilled,(state,action)=>{
            state.loading=false
            state.artical=action.payload
           
        })
        .addCase(getSpecialService.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
    }

  })

  export  default ArticalsSlice.reducer