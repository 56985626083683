import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Attorneys from '../../api/attorneys'
import { useDispatch, useSelector } from 'react-redux'
import { getAllEmployees } from '../../store/pages/employeesSlice'
import {getAllServices} from "../../store/pages/servicesSlice";


const Attorney2 = (props) => {
    const {employees}=useSelector((state)=>state.employees)
    const dispatch=useDispatch()
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    useEffect(()=>{
        dispatch(getAllEmployees())
        dispatch(getAllServices())
        
        },[dispatch])
    return (
        <section className={`wpo-team-section section-padding ${props.tmClass}`}>
            <div className="container">
                <div className="wpo-team-wrap">
                    <div className="wpo-section-title-s2">
                        <span>افضل فريق متخصص</span>
                        <h2 style={{textAlign:'center'}}>فريق العمل</h2>
                    </div>
                    <div className="row">
                        {employees.slice(0,20).map((attorney, aitem) => (
                            <div className="col-lg-3 col-sm-6 col-12" key={aitem}>
                                <div className="wpo-team-item" >
                                    <div className="wpo-team-img">
                                        <img src={attorney.image} alt="" />
                                    </div>
                                    <div className="wpo-team-text">
                                        <h2><Link >{attorney.name}</Link></h2>
                                        <span>{attorney.title}</span>
                                        <div className="social text-white">
                                            <ul className="text-white">
                                            {attorney.description}
                                                {/* <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                                <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link to="/"><i className="ti-instagram"></i></Link></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Attorney2;
