import React from "react";
import { Link } from 'react-router-dom'
import himg from '../../images/slider/salah.png'


const Hero =() => {
    return (
        <section className="static-hero">
    <div className="hero-container">
        <div className="hero-inner">
            <div className="container">
                <div className="hero-text-wrap">
                    <div className="sub-title">
                        <span>شركة احكام للمحاماة والاستشارات القانونية</span>
                    </div>
                    <div className="slide-title ">
                        <h2>الوجـهـة الأولـى لـــرواد الأعـمــال والمنشآت الصغيرة والمتوسطة في جميع أنحاء العالم</h2>
                    </div>
                    <div className="slide-text">
                        <p >نؤمن بالتوافق مع هذا الحراك؛ ولأجل ذلك قمنا بتطوير آليات تقديم الخدمات القانونية للعملاء مع الالتزام بالجودة والتميز.</p>
                        <p >شعارنا : استخدام المعرفة القانونية في تقديم الخدمات القانونية المثلى لعملائنا بما يضمن مصالحهم ونجاح أعمالهم التجارية وقضاياهم.</p>
                        <p >رؤيتنا: أن نكون الوجهة الأولى لرواد الأعمال والمنشآت الصغيرة والمتوسطة في جميع أنحاء العالم للحصول على الخدمات القانونية الأفضل، وخدمات تأسيس الأعمال واستدامتها في المدينة المنورة.</p>
                    </div>
                    <div className="clearfix"></div>
                    <div data-swiper-parallax="500" className="slide-btns">
                        <Link to="/about" className="theme-btn-s2">اكتشف المزيد</Link>
                    </div>
                </div>
                <div className="lawyer-pic bottom-0">
                    <div className="lawyer-shape">
                        <img className="position-relative w-100 h-100 " src={himg} alt=""/>
                    </div>
                    <div className="exprience w-100">
                        <div className="exprience-left  d-flex align-items-center  ">
                            <h6 className="fw-bold">11</h6>
                            <h6 className=" fs-1 fw-bold ">سنة خبرة</h6>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
        </section>
    )
}


export default Hero;