/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../ContactFrom";

const OurLocation = () => {
  return (
  <section className="wpo-contact-pg-section section-padding">
    <div className="container">
      <div className="row">
        <div className="col col-lg-12 offset-lg-1">
          <div className="office-info">
            <div className="row">
              <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                <div className="office-info-item">
                  <div className="office-info-icon">
                    <div className="icon">
                      <i className="fi flaticon-011-maps-and-flags"></i>
                    </div>
                  </div>
                  <div className="office-info-text">
                    <h2>العنوان</h2>
                    <p><a target="_blank" href="https://maps.app.goo.gl/zjouNULcJqHfPa4u5"> المدينة المنورة-الدائرى
                      الثانى-برج الفارسي الدور الثانى,المملكة العربية
                      السعوديه</a></p>

                    <p><a target="_blank" href="  https://maps.app.goo.gl/mJqpNBpzhWi2dUav9">الرياض - حي الياسمين – طريق انس بن مالك,المملكة العربية السعوديه</a></p>

                  </div>
                </div>
              </div>
              <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                <div className="office-info-item">
                  <div className="office-info-icon">
                    <div className="icon">
                      <i className="fi flaticon-037-email-1"></i>
                    </div>
                  </div>
                  <div className="office-info-text">
                    <h2>البريد الالكترونى</h2>
                    <p><a href="mailto:info@ehkaam.com.sa"> info@ehkaam.com.sa</a></p>
                    <p>البريد الإلكتروني، وعادة ما نرد في غضون 24 ساعة.</p>
                  </div>
                </div>
              </div>
              <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                <div className="office-info-item">
                  <div className="office-info-icon">
                    <div className="icon">
                      <i className="fi flaticon-009-telephone"></i>
                    </div>
                  </div>
                  <div className="office-info-text">
                    <h2>اتصل بنا</h2>
                    <p><a href="tel:+966 1234567890">9660148453388+</a></p>
                    <p>الاتصال من 08:00 حتي 18:00 السبت:الخميس</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)
  ;
};

export default OurLocation;
