import React from 'react'
import najez from '../../images/websites/najez12.png'
import GAZT from '../../images/websites/GAZT-o.png'
import nafez from '../../images/websites/نافذ.jpg'
import Emblem from '../../images/websites/1200px-Emblem_of_the_Emirate_of_Medina_Region.svg.png'
import moi from '../../images/websites/moi.png'
import moi_logo_rtl from "../../images/websites/moi_logo_rtl.png"
import imageedit from '../../images/websites/imageedit_3_9383565666.png'
import QiwaLogoFinal from '../../images/websites/QiwaLogoFinal.png'

export const Websites = (props) => {
    return (<section className={`wpo-team-section section-padding ${props.tmClass}`}>
            <div className="container">
                <div className='row mt-5 '>
                    <div className='col-lg-3'>
                        <a href='https://nafith.sa/'>
                            <img src={nafez} className='h-50'/>
                        </a>
                    </div>
                    <div className='col-lg-3'>
                        <a href='https://gazt.gov.sa/ar/Pages/default.aspx'>
                            <img src={GAZT} className='h-50'/>
                        </a>
                    </div>
                    <div className='col-lg-3'>
                        <a href='https://najiz.moj.gov.sa/'>
                            <img src={najez} className='h-50'/>
                        </a>
                    </div>
                    <div className='col-lg-3'>
                        <a href='https://www.my.gov.sa/wps/portal/snp/main'>
                            <img src={Emblem} className='h-50'/>
                        </a>
                    </div>
                    <div className='col-lg-3'>
                        <a href='https://misa.gov.sa/ar/'></a>
                        <img src={moi} className='h-50 w-75'/>
                    </div>
                    <div className='col-lg-3'>
                        <a href='https://www.moi.gov.sa/wps/portal/Home/Home/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziLQPdnT08TIy83Q0dzQwcPc2N_A08TQ3dPY30wwkpiAJKG-AAjgZA_VFgJc7ujh4m5j4GBhY-7qYGno4eoUGWgcbGBo7GUAV4zCjIjTDIdFRUBAApuVo7/dz/d5/L0lDUmlTUSEhL3dHa0FKRnNBLzROV3FpQSEhL2Fy/'></a>
                        <img src={moi_logo_rtl} className='h-50 w-75'/>
                    </div>
                    <div className='col-lg-3'>
                        <a className='https://www.pp.gov.sa/'>
                            <img src={imageedit} className='h-50 '/>
                        </a>
                    </div>
                    <div className='col-lg-3'>
                        <a href='https://www.qiwa.sa/ar'>
                            <img src={QiwaLogoFinal} className='h-50'/>
                        </a>
                    </div>
                </div>
            </div>
        </section>)
}
