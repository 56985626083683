import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { minValueOne } from '../../utils';
import axios from 'axios';
import { BASEURL } from '../../api/Api';

//view services of cart
export const getCart = createAsyncThunk('getCart', async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${BASEURL}/mobile/cart/items/`, {
      headers: {
        authorization: `JWT ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

// add services to cart
export const AddtoCart = createAsyncThunk('AddtoCart', async (data, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.post(`${BASEURL}/mobile/cart/add/`,{
      ...data
    } ,{

      headers: {
        authorization: `JWT ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


export const DeletefromCart = createAsyncThunk('DeletefromCart', async (id, { rejectWithValue }) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.delete(`${BASEURL}/mobile/cart/delete/${id}/`,{
      headers: {
        authorization: `JWT ${token}`
      }
    });
    console.log("hamadaaaaaaaaaaaaaaaa",response.data)
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    loading:false,
    error:null,
    cart: [],
    IsDeleted:true
  },
  reducers: {
    addToCart: (state, action) => {
      const productId = action.payload.id;
      const productQty = action.payload.qty ? action.payload.qty : 1;

      const existingProductIndex = state.cart.findIndex(
        (product) => product.id === productId
      );

      if (existingProductIndex !== -1) {
        state.cart[existingProductIndex] = {
          ...state.cart[existingProductIndex],

          qty: state.cart[existingProductIndex].qty + productQty,
          sum:
            ((state.cart[existingProductIndex].price *
              state.cart[existingProductIndex].discount) /
              100) *
            (state.cart[existingProductIndex].qty + productQty),
        };
      } else {
        state.cart.push({
          ...action.payload,

          qty: action.payload.qty,
          sum:
            ((action.payload.price * action.payload.discount) / 100) *
            action.payload.qty,
        });
      }

    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((item) => item.id !== action.payload);
    },
    incrementQuantity: (state, action) => {
      const productId = action.payload;
      const existingProductIndex = state.cart.findIndex(
        (product) => product.id === productId
      );

      if (existingProductIndex !== -1) {
        state.cart[existingProductIndex] = {
          ...state.cart[existingProductIndex],
          qty: state.cart[existingProductIndex].qty + 1,
        };
      }
    },
    decrementQuantity: (state, action) => {
      const productId = action.payload;
      const existingProductIndex = state.cart.findIndex(
        (product) => product.id === productId
      );

      if (existingProductIndex !== -1) {
        state.cart[existingProductIndex] = {
          ...state.cart[existingProductIndex],
          qty: minValueOne(state.cart[existingProductIndex].qty - 1),
        };
      }
    },
  },
  extraReducers:(builder)=>{
    builder
    .addCase(getCart.pending,(state,action)=>{
        state.loading=true;
        state.error=null
        state.cart=[]
    })
    .addCase(getCart.fulfilled,(state,action)=>{
        state.loading=false
        state.cart=action.payload
        
    })
    .addCase(getCart.rejected,(state,action)=>{
        state.loading=false
        state.error=action.error.message
    })
    builder
    .addCase(AddtoCart.pending,(state,action)=>{
        state.loading=true;
        state.error=null
        state.cart=[]
    })
    .addCase(AddtoCart.fulfilled,(state,action)=>{
        state.loading=false
        state.cart=action.payload
        
    })
    .addCase(AddtoCart.rejected,(state,action)=>{
        state.loading=false
        state.error=action.error.message
    })
    builder
    .addCase(DeletefromCart.pending,(state,action)=>{
        state.loading=true;
        state.error=null
        state.cart=[]
        state.IsDeleted=true
    })
    .addCase(DeletefromCart.fulfilled,(state,action)=>{
      state.loading = false
      state.IsDeleted = false
      console.log("hamadd",action.payload)
        // state.cart = [...state.cart.filter((item) => item.id !== action.payload)];
        
    })
    .addCase(DeletefromCart.rejected,(state,action)=>{
        state.loading=false
        state.error=action.error.message
    })
  }
});

export const {
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
