import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const compareListSlice = createSlice({
  name: 'compareList',
  initialState: {
    compare_list: [],
  },
  reducers: {
    addToCompare: (state, action) => {
      const productId = action.payload.id;
      if (state.compare_list.findIndex((product) => product.id === productId) !== -1) {
        toast.warn('Already added to Compare List');
      } else {
        toast.success('Added to Compare List');
        state.compare_list.push(action.payload);
      }
    },
    removeFromCompareList: (state, action) => {
      const rm_productId = action.payload.id;
      const indexToRemove = state.compare_list.findIndex((product) => product.id === rm_productId);

      if (indexToRemove !== -1) {
        toast.warn('Item Removed from Compare List');
        state.compare_list.splice(indexToRemove, 1);
      } else {
        toast.success('Target not Found');
      }
    },
  },
});

export const { addToCompare, removeFromCompareList } = compareListSlice.actions;

export default compareListSlice.reducer;
