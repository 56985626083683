import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../api/Api";

export const getAllServicesElectronic=createAsyncThunk('getAllServicesElectronic',
async (data, rejectWithValue ) => {
    try {
      const response = await axios.get(`${BASEURL}/mobile/electronic_services/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })

  export const getSpecialElectronic=createAsyncThunk('getSpecialService',
async (id, rejectWithValue ) => {
    try {
      const token = localStorage.getItem('token');
      const headers = {};

      if (token) {
        headers.Authorization = `Token ${token}`;
      }
      const response = await axios.get(`${BASEURL}/mobile/electronic_services/${id}`,{
        headers:headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })
  const ElectonicSlice=createSlice({
    name:'electronics',
    initialState:{
        loading:false,
        loadingEl:false,
        error:null,
        electronics:{},
        electronic:{},
       
    },
    reducers:{
     
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getAllServicesElectronic.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.electronics={}
        })
        .addCase(getAllServicesElectronic.fulfilled,(state,action)=>{
            state.loading=false
            state.electronics=action.payload
            
        })
        .addCase(getAllServicesElectronic.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
        builder
        .addCase(getSpecialElectronic.pending,(state,action)=>{
            state.loading=true;
            state.error=null
            state.electronic={}
        })
        .addCase(getSpecialElectronic.fulfilled,(state,action)=>{
            state.loading=false
            state.electronic=action.payload
           
        })
        .addCase(getSpecialElectronic.rejected,(state,action)=>{
            state.loading=false
            state.error=action.error.message
        })
    }

  })
  
  export  default ElectonicSlice.reducer