import React, { useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import Attorneys from '../../api/attorneys'
import { useDispatch, useSelector } from 'react-redux';
import { getAllServices } from '../../store/pages/servicesSlice';
import { useSelect } from '@mui/base';
import { getAllEmployees, getSpecialEmployee } from '../../store/pages/employeesSlice';


const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    rtl: true,
    initialSlide:2,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1100,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const Attorney = (props) => {
    const {employees}=useSelector((state)=>state.employees)
    const dispatch=useDispatch()
    const ClickHandler = (id) => {
        window.scrollTo(10, 0);
        dispatch(getSpecialEmployee(id))
    }
    useEffect(()=>{
        dispatch(getAllEmployees())
        
        },[dispatch])
    return (
        <section className="wpo-team-section section-padding pt-0">
            <div className="container">
                <div className="wpo-team-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="wpo-section-title">
                                <span>العمل مع الأفضل</span>
                                <h2>فريق العمل بشركة احكام للمحاماة والاستشارات القانونية</h2>
                            </div>
                        </div>
                    </div>
                    <div className="wpo-team-active">
                        <Slider {...settings}>
                            {employees.map((attorney, aitem) => (
                                <div className="wpo-team-item" key={aitem}>
                                    <div className="wpo-team-img">
                                        <img src={attorney.image} alt=""  />
                                    </div>
                                    <div className="wpo-team-text">
                                        <h2><Link >{attorney.name}</Link></h2>
                                        <span>{attorney.title}</span>
                                        <div className="social">
                                            <ul>
                                            <li className="text-white">{attorney.description}</li>
                                                {/* <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                                <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                                <li><Link to="/"><i className="ti-instagram"></i></Link></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    {/* <div className={`visible-text s2 ${props.vClass}`}>
                        <h2>Attorneys</h2>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default Attorney;
