import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Practices from '../../api/Practices';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAllServices, getSpecialService } from '../../store/pages/servicesSlice';

const Sidebar = (props) => {

    const dispatch=useDispatch()
    const location=useLocation()
    const navigate=useNavigate()
    const {services,loading}=useSelector((state)=>state.services)
   

useEffect(()=>{
      dispatch(getAllServices());
      window.scrollTo(10, 0);
},[dispatch])

    const ClickHandler = (practice_id) => {
        dispatch(getSpecialService(practice_id));
        // window.scrollTo(10, 0);
    }

    return (
        <div className="col-lg-3 col-md-8 order-lg-1 order-2   ">
            <div className="wpo-single-sidebar wpo-contact-pg-section  ">
                <div className="wpo-service-widget wpo-section-title  wpo-single-sidebar ">
                    <h2 className='d-flex justify-content-start me-5'>خدمات اخرى</h2>
                    <ul>
                        {services?.map((practice, Pitem) => (
                            <li key={Pitem}
                            onClick={()=>ClickHandler(practice.id)}
                            >
                               
                               <Link to={`/practice-single/${practice.id}`} >{practice?.title}</Link>
                                </li>
                        ))}
                    </ul>
                </div>
                {/* <div className="wpo-service-widget widget">
                    <h2>Our Attorneys</h2>
                    <ul>
                        <li><Link onClick={ClickHandler} to="/">Brooklyn Simmons</Link></li>
                        <li><Link onClick={ClickHandler} to="/">Leslie Alexander</Link></li>
                        <li><Link onClick={ClickHandler} to="/">Jenny Wilson</Link></li>
                        <li><Link onClick={ClickHandler} to="/">Robert Fox</Link></li>
                        <li><Link onClick={ClickHandler} to="/">Savannah Nguyen</Link></li>
                        <li><Link onClick={ClickHandler} to="/">Ralph Edwards</Link></li>
                        <li><Link onClick={ClickHandler} to="/">Darlene Robertson</Link></li>
                        <li><Link onClick={ClickHandler} to="/">Jerome Bell</Link></li>
                    </ul>
                </div>

                <div className="wpo-contact-widget">
                    <i className="fi flaticon-006-law"></i>
                    <span>Get Free Advice From This Case</span>
                    <h2>+0 865.369.5698</h2>
                    <Link onClick={ClickHandler} to="/contact">Call Us 24/7</Link>
                </div> */}
            </div>
        </div>
    )
};
export default Sidebar;

