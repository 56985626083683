import { createSlice } from '@reduxjs/toolkit';
import { RECEIVE_PRODUCTS } from '../actions/type';

const productSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    symbol: '$',
  },
  reducers: {
    receiveProducts: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const { receiveProducts } = productSlice.actions;

export default productSlice.reducer;
