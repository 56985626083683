import React, { Fragment } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { AddtoCart, addToCart } from "../../store/pages/cart";
import ShopProduct from '../../components/ShopProduct';
import api from "../../api";
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer';

const ShopPage = ({ addToCart }) => {

    const productsArray = api();
    const dispatch=useDispatch()
    const { electronics, loading } = useSelector((state) => state.electronics);
    const addToCartProduct = (product, qty = 1) => {
        console.log(product);
        addToCart(product, qty);
        dispatch(AddtoCart({service_id:product.id,qty,}))
    };
    const products = []
    return (
        <Fragment>
            <Navbar hclass={'wpo-header-style-5'} topbarClass={'tb-block'} />
            <PageTitle pageTitle={'الخدمات الالكترونية'} pagesub={'المتجر'} />
                        {loading? <div className="col-lg-12">
                                <div className="wpo-section-title">
                                    <h2>جاري التحميل</h2>
                                </div>
                            </div>
                            : <div className="col-lg-12">
                            <ShopProduct
                                tmClass={'dark-bg s2'}
                                addToCartProduct={addToCartProduct}
                                products={products}
                            />
                        </div>}
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default connect(null, { addToCart })(ShopPage);