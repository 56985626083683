import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'
import ReactWhatsapp from 'react-whatsapp';

const Scrollbar = () => {

    return(
        <div className="col-lg-12">
            <div className="header-menu">
                <ul className="smothscroll">    
                    <li> <ReactWhatsapp number="+966148453388" message="مرحبا بك فى شركة إحكام" ><i className="fa fa-whatsapp fa-lg"/></ReactWhatsapp></li>
                    <li><AnchorLink href='#scrool'><i className="ti-arrow-up"></i></AnchorLink></li>
                </ul>
            </div>
        </div>
        
    )
}

export default Scrollbar;
