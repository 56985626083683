import React, { useEffect, useState } from 'react';
import { useNavigate,Outlet } from 'react-router-dom';

const  AuthComponent = ({children})=> {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthentication = async () => {
            const token = localStorage.getItem('token');
            console.log(token)
            if (token) {
                setIsAuthenticated(true);
            } else {
                // If the user is not authenticated, redirect to the login page
                navigate('/signin');
            }
        };

        checkAuthentication();
    }, [navigate]);

    return isAuthenticated ? <Outlet/> : null;
};

export default AuthComponent;